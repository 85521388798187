import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import Post from '../components/Post';
import Pagination from '../components/Pagination';

export default function Pages({ pageContext, data, location }) {
    const siteTitle = data.site.siteMetadata.title;
    const { posts, page, pagesSum, prevPath, nextPath } = pageContext;

    return (
        <Layout location={location} title={siteTitle}>
            <SEO htmlClass="weblog" title="Log" />
            <header>
                <h1>Dispatch</h1>
                <p>
                    By <Link to="/">Rowan Oulton</Link>
                </p>
            </header>
            {posts.map((node) => {
                return (
                    <Post
                        key={node.fields.slug}
                        slug={node.fields.slug}
                        date={node.frontmatter.date}
                        html={node.html}
                    />
                );
            })}
            <Pagination prevPath={prevPath} nextPath={nextPath} />
        </Layout>
    );
}

Pages.propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
    location: PropTypes.object,
};

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
